<template>
	<div class="relative">
		<DatePicker
			v-model="date"
			:placeholder="placeholder"
			:lang="lang"
			:input-class="`w-full py-3 sm:py-4 pl-4 pr-11 bg-white text-black text-base sm:text-lg font-bold border-0 ring-0 focus:ring-0 cursor-pointer rounded-md placeholder:text-gray-400 ${inputClass}`"
			format="DD.MM.YYYY"
			title-format="DD.MM.YYYY"
			:disabled-date="disabledDates"
			:defaultValue="defaultDate"
			:editable="false"
			:clearable="false"
			:appendToBody="false"
			@open="onOpen"
		/>
		<img
			v-if="addCancel"
			src="/design/close-blue.svg"
			class="absolute right-2 top-1.5 sm:top-3.5 p-2.5 w-9 cursor-pointer"
			@click="$emit('input', null)"
			alt
		/>
		<img
			v-else
			src="/design/calendar.svg"
			class="absolute right-4 top-3 sm:top-5 w-input-ico pointer-events-none"
			alt
		/>
		<div class="input-error-box bg-my-red" :class="[inputClassError]" v-if="error">
			{{ errorText }}
		</div>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import {getDatepickerTranslations} from '~/scripts/helpers'

export default {
	components: {
		DatePicker
	},
	props: {
		value: Date,
		previousDate: Date,
		inputClass: String,
		firstOfTwo: Boolean,
		addCancel: Boolean,
		placeholder: String,
		error: Boolean,
		errorText: String,
		inputClassError: String
	},

	data() {
		return {
			lang: getDatepickerTranslations(this.$i18n.locale),
			date: this.value,
			isTouchDevice: !!(process.client && ('ontouchstart' in window || navigator.maxTouchPoints)),
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.date = this.value
		})
	},

	methods: {
		onOpen(e) {
			this.$nextTick(() => {
				const calendar = e.target.parentNode.nextSibling
				const calendarTop = calendar.getBoundingClientRect().top
				const calendarHeight = calendar.offsetHeight
				const windowHeight = window.innerHeight
				const scrollTop = calendar.ownerDocument.defaultView.pageYOffset

				calendar.querySelectorAll('button').forEach(button => {
					button.tabIndex = -1
				})

				if (calendarTop + calendarHeight > windowHeight) {
					window.scrollTo({
						top: scrollTop + (calendarTop + calendarHeight - windowHeight),
						behavior: 'smooth'
					})
				}
			})
		},

		disabledDates(date) {
			const minDate = new Date(this.lowerLimit);
			const maxDate = new Date(this.upperLimit);

			minDate.setHours(0, 0, 0, 0);
			maxDate.setHours(0, 0, 0, 0);

			return date < minDate || date > maxDate;
		},
	},

	computed: {
		/**
		 * Use to set a lower date limit.
		 */
		lowerLimit() {
			let date;

			if (this.previousDate === undefined) {
				date = new Date();
			} else {
				date = this.previousDate;
			}

			return date;
		},

		/**
		 * Use to set a upper date limit.
		 */
		upperLimit() {
			const now = new Date();

			now.setMonth(now.getMonth() + 11);

			return now;
		},
		defaultDate() {
			if (this.previousDate) {
				return this.previousDate
			} else {
				return new Date()
			}
		}
	},

	watch: {
		/**
		 * Use to track the change in the previous date.
		 */
		previousDate(value, oldValue) {
			let updatedDate = null;

			if (this.value && this.value < value) {
				if (oldValue == null) {
					updatedDate = null;
				} else {
					const diffDays = Math.ceil((this.value - oldValue) / (1000 * 60 * 60 * 24));
					updatedDate = new Date(value.getTime());
					updatedDate.setDate(value.getDate() + diffDays);
				}

				this.$emit('input', updatedDate);
			}
		},

		/**
		 *
		 */
		value(date) {
			this.date = date;
			this.$emit('update:error', false);
		},

		date(value) {
			this.$emit('input', value)

		}
	},
}
</script>
