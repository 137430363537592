<i18n lang="json5">{
	ru: {
		loading: 'Загрузка данных...',
		date_required: 'Укажите дату',
		departure_station_required: 'Выберите станцию отправления',
		arrival_station_required: 'Выберите станцию назначения',
	},
	uz: {
		loading: 'Maʼlumotlar yuklanmoqda...',
		date_required: 'Sanasini koʼrsating',
		departure_station_required: 'Выберите станцию отправления',
		arrival_station_required: 'Выберите станцию назначения',
	}
}</i18n>

<template>
	<form class="relative sm:min-h-[27rem] lg:min-h-[14rem]" @submit.prevent="onSubmit">
		<div class="xl:flex">
			<div class="lg:flex xl:flex-grow xl:basis-0">
				<div class="lg:flex-grow">
					<div class="relative lg:border-my-gray-400 lg:border-r">
						<img
							src="/design/placeholder.svg"
							class="absolute left-4 sm:left-5 lg:left-6 top-3 sm:top-5 w-input-ico pointer-events-none"
							alt
						/>
						<input
							type="text"
							v-model.trim="departure.query"
							class="w-full py-3 sm:py-4 pl-12 sm:pl-14 pr-4 sm:pr-6 bg-white text-black text-base sm:text-lg font-bold rounded-md border-0 focus:ring-0 placeholder:text-gray-400 touch-none lg:rounded-r-none"
							placeholder="Откуда"
							@input="search(departure, arrival.stationCode)"
						>
						<div
							class="absolute top-full w-full max-h-96 bg-white border border-t-0 shadow-hint-top text-base z-20 overflow-y-auto overflow-x-hidden overscroll-contain rounded-md text-left"
							v-if="departure.loading || departure.showSuggestions && departure.stations && departure.stations.length"
							v-click-outside="() => onClickOutside(departure)"
						>
							<div
								class="relative border-tm pt-1 pb-2 px-3 w-full"
								v-if="departure.loading"
							>
								<div class="relative text-center">
									<div class="loader" />
									{{ $t('loading') }}
								</div>
							</div>
							<template v-else>
								<div
									v-for="station in departure.stations"
									:key="station.code"
									class="relative group border-t pt-1 pb-2 px-3 w-full cursor-pointer hover:bg-my-blue hover:text-white"
									@click="selectStation(departure, station.code, station.name, station.station)"
								>
									<div class="flex items-center justify-between w-full leading-tight">
										<span class="flex-grow font-bold">
											{{ station.station }}
										</span>
										<span class="pl-2 text-xs text-gray-500 group-hover:text-white">
											{{ station.country }}
										</span>
									</div>
								</div>
							</template>
						</div>
						<div v-if="!$v.departure.stationCode.required && submitted" class="input-error-box bg-my-red rounded-t-md lg:rounded-tr-none">
							{{ $t('departure_station_required') }}
						</div>
					</div>
					<div class="text-[rgba(255,255,255,0.75)]">
						<span class="cursor-pointer lg:hover:underline" @click="selectStation(departure, 2900000, 'TASHKENT', 'ТАШКЕНТ')">Ташкент</span>,
						<span class="cursor-pointer lg:hover:underline" @click="selectStation(departure, 2900700, 'SAMARKAND', 'САМАРКАНД')">Самарканд</span>,
						<span class="cursor-pointer lg:hover:underline" @click="selectStation(departure, 2900800, 'BUKHARA', 'БУХАРА')">Бухара</span>,
						<span class="cursor-pointer lg:hover:underline" @click="selectStation(departure, 2900172, 'KHIVA', 'ХИВА')">Хива</span>
					</div>
				</div>

				<div class="lg:flex-grow mt-4 lg:mt-0">
					<div class="relative xl:border-my-gray-400 xl:border-r">
						<img
							src="/design/placeholder.svg"
							class="absolute left-4 sm:left-5 lg:left-6 top-3 sm:top-5 w-input-ico pointer-events-none"
							alt
						/>
						<input
							type="text"
							v-model.trim="arrival.query"
							class="w-full py-3 sm:py-4 pl-12 sm:pl-14 pr-4 sm:pr-6 bg-white text-black text-base sm:text-lg font-bold rounded-md border-0 focus:ring-0 placeholder:text-gray-400 touch-none lg:rounded-l-none xl:rounded-r-none"
							placeholder="Куда"
							@input="search(arrival, departure.stationCode)"
						>
						<div
							class="absolute top-full w-full max-h-96 bg-white border border-t-0 shadow-hint-top text-base z-20 overflow-y-auto overflow-x-hidden overscroll-contain rounded-md text-left"
							v-if="arrival.loading || arrival.showSuggestions && arrival.stations && arrival.stations.length"
							v-click-outside="() => onClickOutside(arrival)"
						>
							<div
								class="relative border-tm pt-1 pb-2 px-3 w-full"
								v-if="arrival.loading"
							>
								<div class="relative text-center">
									<div class="loader" />
									{{ $t('loading') }}
								</div>
							</div>
							<template v-else>
								<div
									v-for="station in arrival.stations"
									:key="station.code"
									class="relative group border-t pt-1 pb-2 px-3 w-full cursor-pointer hover:bg-my-blue hover:text-white"
									@click="selectStation(arrival, station.code, station.name, station.station)"
								>
									<div class="flex items-center justify-between w-full leading-tight">
										<span class="flex-grow font-bold">
											{{ station.station }}
										</span>
										<span class="pl-2 text-xs text-gray-500 group-hover:text-white">
											{{ station.country }}
										</span>
									</div>
								</div>
							</template>
						</div>
						<div v-if="!$v.arrival.stationCode.required && submitted" class="input-error-box bg-my-red rounded-t-md lg:rounded-tl-none xl:rounded-tr-none">
							{{ $t('arrival_station_required') }}
						</div>
						<div
							class="absolute left-0 top-1/2 -translate-x-4 -translate-y-[50%] hidden lg:flex items-center justify-center w-8 h-8 border border-gray-300 bg-white rounded-full shadow-md cursor-pointer"
							@click="exchange({
								departure: {
									stationCode: arrival.stationCode,
									query: arrival.query,
								},
								arrival: {
									stationCode: departure.stationCode,
									query: departure.query,
								},
							})"
						>
							<img src="/design/exchange.svg" class="w-4" width="16" height="16" alt="" />
						</div>
					</div>
					<div class="text-[rgba(255,255,255,0.75)]">
						<span class="cursor-pointer lg:hover:underline" @click="selectStation(arrival, 2900700, 'SAMARKAND', 'САМАРКАНД')">Самарканд</span>,
						<span class="cursor-pointer lg:hover:underline" @click="selectStation(arrival, 2000000, 'MOSKVA', 'МОСКВА')">Москва</span>,
						<span class="cursor-pointer lg:hover:underline" @click="selectStation(arrival, 2024000, 'SAMARA',  'САМАРА')">Самара</span>
					</div>
				</div>
			</div>
			<div class="lg:flex flex-shrink-0">
				<div class="mt-4 xl:mt-0 flex lg:flex-grow">
					<div class="flex-grow">
						<div class="relative border-my-gray-400 border-r xl:w-44">
							<TrainCalendar
								v-model="$v.departure.date.$model"
								:placeholder="'Туда'"
								:input-class="'rounded-r-none xl:rounded-l-none'"
							/>
							<div v-if="!$v.departure.date.required && submitted" class="input-error-box bg-my-red rounded-t-md xl:rounded-tl-none rounded-tr-none">
								{{ $t('date_required') }}
							</div>
						</div>
					</div>
					<div class="flex-grow">
						<div class="relative lg:border-my-gray-400 lg:border-r mt-0 xl:w-44">
							<TrainCalendar
								v-model="arrival.date"
								:previous-date="departure.date"
								:placeholder="'Обратно'"
								:input-class="'rounded-l-none lg:rounded-r-none'"
								:addCancel="!!arrival.date"
							/>
						</div>
					</div>
				</div>
				<div class="mt-10 lg:mt-4 xl:mt-0">
					<button class="w-full px-6 py-3 sm:py-4 rounded-md lg:rounded-l-none bg-gradient-to-b from-my-blue-from to-my-blue-to text-white text-base text-center sm:text-lg font-bold cursor-pointer whitespace-nowrap">
						Найти билеты
					</button>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import Multiselect from './Multiselect.vue'
import TrainCalendar from './TrainCalendar'

export default {
	name: 'TrainSearchForm',
	components: {
		Multiselect,
		TrainCalendar,
	},
	props: {
		requestData: Object,
	},
	data() {
		return {
			departure: {
				query: '',
				stationName: '',
				stationCode: null,
				stations: null,
				date: null,
				loading: false,
				showSuggestions: false,
			},
			arrival: {
				query: '',
				stationName: '',
				stationCode: null,
				stations: null,
				date: null,
				loading: false,
				showSuggestions: false,
			},
			submitted: false,
		}
	},
	validations: {
		departure: {
			stationCode: {
				required,
			},
			date: {
				required,
			},
		},
		arrival: {
			stationCode: {
				required,
			},
			// date: {
			// 	required,
			// },
		},
	},
	mounted() {
		this.$store.dispatch('trains/getToken')
		// if (!this.stations) {
		// 	await this.fetchStations()
		// }
	},
	methods: {
		/*async fetchStations() {
			try {
				await this.$store.dispatch('trains/getStations')
			} catch (e) {}
		},*/
		async search(direction, ignoreCode) {
			direction.stationName = ''
			direction.stationCode = null
			direction.stations = null

			if (direction.query.length > 1) {
				try {
					direction.loading = true
					direction.stations = (await this.$store.dispatch('trains/getStations', {name: direction.query})).filter(station => station.code !== ignoreCode)
					direction.loading = false
					direction.showSuggestions = true
				} catch (e) {
					if (!this.$axios.isCancel(e)) {
						direction.loading = false
						direction.showSuggestions = false
					}
					if (!this.$axios.isAxiosError(e)) {
						this.$sentry?.captureException(e)
					}
				}
			} else {
				await this.$store.dispatch('trains/cancelRequest')
				direction.loading = false
				direction.showSuggestions = false
			}
		},
		selectStation(direction, code, name, query) {
			direction.stationName = name
			direction.stationCode = code
			direction.query = query
			direction.showSuggestions = false
		},
		onClickOutside(direction) {
			direction.showSuggestions = false
		},
		exchange({departure, arrival}) {
			this.departure.stationName = departure.stationName
			this.departure.stationCode = departure.stationCode
			this.departure.query = departure.query
			this.arrival.stationName = arrival.stationName
			this.arrival.stationCode = arrival.stationCode
			this.arrival.query = arrival.query
		},
		async onSubmit() {
			this.submitted = true
			this.$v.$touch()

			if (!this.$v.$invalid) {
				const query = [
					this.departure.stationName,
					this.arrival.stationName,
					this.parseDate(this.departure.date),
				]

				if (this.arrival.date) {
					query.push(this.parseDate(this.arrival.date))
				}

				/*if (query === this.$route.params.trainQuery && this.$route.name === 'train-offers') {
					this.$emit('refreshPage')
				} else {*/
					await this.$router.push({
						name: 'train-offers',
						params: {trainQuery: query.join('_')}
					})
				//}
			}
		},
		parseDate(date) {
			return `${date.getFullYear()}${this.addZero(date.getMonth() + 1)}${this.addZero(date.getDate())}`
		},
		/*async filterStations(query) {
			if (query === null) {
				return this.stations
			}
			return this.stations.filter(station => Object.values(station.stations).some(name => name.includes(query.toUpperCase())))
		},*/
	},
	computed: {
		/*stations() {
			const stations = this.$store.getters['trains/stations']
				? this.$store.getters['trains/stations'].map(station => ({
					...station,
					station: station.stations[this.$i18n.locale]
				}))
				: null

			return stations
		},*/
	},
	watch: {
		// async stations() {
		// 	this.$refs.stations.refreshOptions()
		// },
	},
}
</script>
