<template>
	<div
		class="insurance-calendar relative"
		:class="{second: !firstOfTwo}"
	>
		<DatePicker
			v-if="windowWidth < 1024"
			ref="datepicker"
			v-model="date"
			:placeholder="placeholder"
			:lang="lang"
			:input-class="`w-full py-3 sm:py-4 pl-4 pr-11 bg-white text-black text-base sm:text-lg font-bold border-0 ring-0 focus:ring-0 cursor-pointer rounded-md placeholder:text-gray-400 ${inputClass}`"
			format="DD.MM.YYYY"
			title-format="DD.MM.YYYY"
			:disabled-date="disabledDates"
			:disabled-calendar-changer="disabledDates"
			:defaultValue="defaultDate"
			:editable="false"
			:clearable="false"
			:appendToBody="false"
			@open="onOpen"
		/>
		<DatePicker
			v-else
			ref="datepicker"
			v-model="date"
			:placeholder="placeholder"
			:lang="lang"
			:input-class="`w-full py-3 sm:py-4 pl-4 pr-11 bg-white text-black text-base sm:text-lg font-bold border-0 ring-0 focus:ring-0 cursor-pointer rounded-md placeholder:text-gray-400 ${inputClass}`"
			format="DD.MM.YYYY"
			:editable="false"
			:clearable="false"
			:appendToBody="false"
			@open="onOpen($event);highlightSelectedDate()"
			@change="defaultDate = $event"
			@calendar-change="highlightSelectedDate"
			@close="onClose"
		>
			<template v-slot:content="slotProps" v-if="windowWidth >= 1024">
				<div class="flex">
					<div class="pr-2 mx-panel">
						<CalendarPanel
							type="date"
							format="DD.MM.YYYY"
							title-format="DD.MM.YYYY"
							:disabled-date="disabledDates"
							:disabled-calendar-changer="disabledDates"
							:defaultValue="defaultDate"
							@select="slotProps.emit"
							@update:calendar="defaultDate = $event"
						/>
					</div>
					<div class="pl-2 mx-panel">
						<CalendarPanel
							type="date"
							format="DD.MM.YYYY"
							title-format="DD.MM.YYYY"
							:disabled-date="disabledDates"
							:disabled-calendar-changer="disabledDates"
							:defaultValue="moment(defaultDate).add(1, 'months').toDate()"
							@select="slotProps.emit"
							@update:calendar="defaultDate = moment($event).subtract(1, 'months').toDate()"
						/>
					</div>
				</div>
			</template>
		</DatePicker>
		<img
			src="/design/calendar.svg"
			class="absolute right-4 top-3 sm:top-5 w-input-ico pointer-events-none"
			alt
		/>
		<div class="input-error-box bg-my-red" :class="[inputClassError]" v-if="error">
			{{ errorText }}
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import {getDatepickerTranslations} from '~/scripts/helpers'
const { CalendarPanel } = DatePicker;

export default {
	components: {
		DatePicker,
		CalendarPanel,
	},
	props: {
		value: Date,
		previousDate: Date,
		inputClass: String,
		firstOfTwo: Boolean,
		addCancel: Boolean,
		placeholder: String,
		error: Boolean,
		errorText: String,
		inputClassError: String,
		isEndDate: Boolean,
	},

	data() {
		return {
			moment: moment,
			lang: getDatepickerTranslations(this.$i18n.locale),
			date: this.value,
			defaultDate: this.value || this.previousDate || new Date(),
			windowWidth: process.client ? window.innerWidth : null,
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.date = this.value
			this.defaultDate = this.value || this.previousDate || new Date()
			window.addEventListener('resize', this.onResize)
		})
	},

	destroyed() {
		window.removeEventListener('resize', this.onResize)
	},

	methods: {
		onResize() {
			this.windowWidth = window.innerWidth
			if (this.windowWidth >= 1024) {
				this.highlightSelectedDate()
			}
		},
		highlightSelectedDate() {
			if (this.date) {
				this.$nextTick(() => {
					const $el = this.$refs.datepicker.$el || this.$refs.datepicker[0].$el
					const activeDate = moment(this.date).format('DD.MM.YYYY')
					const activeCell = $el.querySelector(`td[title="${activeDate}"]:not(.not-current-month)`)

					$el.querySelectorAll(`td.active`).forEach(cell => cell.classList.remove('active'))

					if (activeCell) {
						activeCell.classList.add('active')
					}
				})
			}
		},
		onOpen(e) {
			this.$nextTick(() => {
				const calendar = e.target.parentNode.nextSibling
				const calendarTop = calendar.getBoundingClientRect().top
				const calendarHeight = calendar.offsetHeight
				const windowHeight = window.innerHeight
				const scrollTop = calendar.ownerDocument.defaultView.pageYOffset

				calendar.querySelectorAll('button').forEach(button => {
					button.tabIndex = -1
				})

				if (calendarTop + calendarHeight > windowHeight) {
					window.scrollTo({
						top: scrollTop + (calendarTop + calendarHeight - windowHeight),
						behavior: 'smooth'
					})
				}
			})
		},
		onClose() {
			this.defaultDate = this.date || this.previousDate || new Date()
		},
		disabledDates(date) {
			const minDate = new Date(this.lowerLimit);
			const maxDate = new Date(this.upperLimit);

			minDate.setHours(0, 0, 0, 0);
			maxDate.setHours(0, 0, 0, 0);

			return date < minDate || date > maxDate;
		},
	},

	computed: {
		/**
		 * Use to set a lower date limit.
		 */
		lowerLimit() {
			return this.previousDate || new Date()
		},

		/**
		 * Use to set a upper date limit.
		 */
		upperLimit() {
			const now = new Date();

			now.setMonth(now.getMonth() + 11);

			return now;
		},
	},

	watch: {
		/**
		 * Use to track the change in the previous date.
		 */
		previousDate(value, oldValue) {
			let updatedDate = null

			this.defaultDate = value

			if (this.value && this.value < value) {
				if (oldValue == null) {
					updatedDate = null;
				} else {
					const diffDays = Math.ceil((this.value - oldValue) / (1000 * 60 * 60 * 24));
					updatedDate = new Date(value.getTime());
					updatedDate.setDate(value.getDate() + diffDays);
				}

				this.$emit('input', updatedDate);
			}
		},

		/**
		 *
		 */
		value(date) {
			this.date = date;
			this.$emit('update:error', false);
		},

		date(value) {
			this.$emit('input', value)

		}
	},
}
</script>

<style>
@media (max-width: 1023px) {
	.insurance-calendar:not(.second) .mx-datepicker-popup {
		left: 0 !important;
		right: auto;
	}
	.insurance-calendar.second .mx-datepicker-popup {
		left: auto !important;
		right: 0;
	}
}
@media (min-width: 1280px) {
	.insurance-calendar:not(.second) .mx-datepicker-popup {
		left: 0 !important;
		right: auto;
	}
}
@media (min-width: 1800px) {
	.insurance-calendar .mx-datepicker-popup {
		left: 0 !important;
		right: auto;
	}
}
</style>
