<i18n lang="json5">{
	ru: {
		visiting_country: 'Страна посещения',
		visiting_country_required: 'Укажите страну посещения',
		country_list_error: 'Не удалось загрузить список стран',
		no_matches: 'Совпадений не найдено',
		date_required: 'Укажите дату',
		insurance_begin: 'Начало страхования',
		insurance_end: 'Конец страхования',
		selected_days: 'Выбрано дней:',
		submit: 'Оформить полис',
		RU: 'Россия',
		TR: 'Турция',
		KZ: 'Казахстан',
		AE: 'ОАЭ',
		KG: 'Киргизия',
		KR: 'Южная Корея',
		MY: 'Малайзия',
	},
	uz: {
		visiting_country: 'Borish mumkin boʼlgan mamlakatlar',
		visiting_country_required: 'Bormoqchi boʼlgan mamlakatingizni koʼrsating',
		country_list_error: 'Mamlakatlar roʼyxatini yuklab boʼlmadi',
		no_matches: 'Moslik topilmadi',
		date_required: 'Sanasini koʼrsating',
		insurance_begin: 'Sugʼurta muddatining boshlanishi',
		insurance_end: 'Sugʼurta muddatining tugashi',
		selected_days: 'Kunlar tanlandi:',
		submit: 'Polisni rasmiylashtirish',
		RU: 'Rossiya',
		TR: 'Turkiya',
		KZ: 'Qozogʼiston',
		AE: 'BAA',
		KG: 'Qirgʼiziston',
		KR: 'Janubiy Koreya',
		MY: 'Malayziya',
	}
}</i18n>

<template>
	<form
		class="relative"
		:class="{
			'sm:min-h-[27rem] lg:min-h-[14rem]': $route.name === 'home',
			'-mb-10 lg:-mb-6': $route.name !== 'home',
		}"
		@submit.prevent="onSubmit"
	>
		<div class="lg:flex lg:flex-wrap xl:flex-nowrap">
			<div class="relative flex-[0_0_100%] xl:flex-[0_0_40%] 2xl:flex-[0_0_50%] mb-5">
				<img
					src="/design/placeholder.svg"
					class="absolute left-4 sm:left-5 lg:left-6 top-3 sm:top-5 z-10 w-input-ico pointer-events-none"
					alt
				/>
				<Multiselect
					ref="multiselect"
					v-model="$v.selectedCountries.$model"
					valueProp="code"
					label="name"
					mode="tags"
					:delay="0"
					:max="5"
					:placeholder="$t('visiting_country')"
					:noOptionsText="$t('country_list_error')"
					:noResultsText="$t('no_matches')"
					:options="async function(query) {
						return await filterCountries(query)
					}"
					:searchable="true"
					:canClear="false"
					class="!h-auto !min-h-[3rem] sm:!min-h-[3.75rem] !border-0 !rounded-md xl:!rounded-r-none"
					:classes="{
						containerActive: 'is-active !shadow-none',
						singleLabel: 'multiselect-single-label !pl-4 !pr-6',
						caret: 'multiselect-caret !mr-2',
						singleLabelText: 'multiselect-single-label-text !text-my-black !font-bold',
						placeholder: 'multiselect-placeholder !pl-12 sm:!pl-14 !pr-6 !text-gray-400 !leading-5 !font-bold sm:!text-lg',
						tagsSearch: 'multiselect-tags-search !pl-1 focus:ring-0 !font-bold',
						dropdown: `multiselect-dropdown !left-0 !right-0 !border-gray-200 !rounded-md ${selectedCountries.length === 0 && (!query || query.length < 2) ? '!hidden' : ''}`,
						options: 'multiselect-options !max-h-96',
						option: 'multiselect-option !block !px-3 !py-1 hover:!bg-my-blue hover:!text-white',
						optionDisabled: 'is-disabled pointer-events-none',
						optionPointed: 'is-pointed !bg-my-blue !text-white',
						optionSelected: 'is-selected !bg-my-blue/20 !text-inherit',
						optionSelectedPointed: 'is-selected is-pointed !bg-my-blue !text-white',
						tagsSearchWrapper: 'multiselect-tags-search-wrapper !mx-0',
						tags: 'multiselect-tags !mt-0 !pl-[44px] sm:!pl-[52px]',
						tagRemove: isTouchDevice ? 'multiselect-tag-remove text-my-blue hover:!bg-transparent' : 'multiselect-tag-remove text-my-blue hover:!bg-my-blue hover:!text-white',
						tagRemoveIcon: 'multiselect-tag-remove-icon !w-4 !h-4 !opacity-100',
					}"
				>
					<template v-slot:tag="{ option, handleTagRemove, disabled }">
						<div class="multiselect-tag !mb-0 !pl-1 !bg-transparent !text-black !text-base">
							{{ option.name }}
							<span
								v-if="!disabled"
								:class="isTouchDevice ? 'multiselect-tag-remove text-my-blue hover:!bg-transparent' : 'multiselect-tag-remove text-my-blue hover:!bg-my-blue hover:!text-white'"
								@mousedown.prevent
								@click.prevent="handleTagRemove(option, $event)"
							>
								<span class="multiselect-tag-remove-icon !w-4 !h-4 !opacity-100"></span>
							</span>
						</div>
					</template>
				</Multiselect>
				<div v-if="!$v.selectedCountries.required && submitted" class="input-error-box bg-my-red rounded-t-md xl:rounded-tr-none">
					{{ $t('visiting_country_required') }}
				</div>
				<div class="text-[rgba(255,255,255,0.75)]">
					<span class="cursor-pointer lg:hover:underline" @click="toggleCountry('RU')">{{ $t('RU') }}</span>,
					<span class="cursor-pointer lg:hover:underline" @click="toggleCountry('TR')">{{ $t('TR') }}</span>,
					<span class="cursor-pointer lg:hover:underline" @click="toggleCountry('KZ')">{{ $t('KZ') }}</span>,
					<span class="cursor-pointer lg:hover:underline" @click="toggleCountry('AE')">{{ $t('AE') }}</span>
				</div>
			</div>
			<div class="relative grow flex flex-nowrap mb-11">
				<div class="grow relative xl:border-l border-r">
					<InsuranceCalendar
						v-model="$v.departureDate.$model"
						:placeholder="$t('insurance_begin')"
						:input-class="'rounded-r-none xl:rounded-l-none'"
						:first-of-two="true"
					/>
					<div v-if="!$v.departureDate.required && submitted" class="input-error-box bg-my-red rounded-t-md xl:rounded-tl-none rounded-tr-none">
						{{ $t('date_required') }}
					</div>
					<div class="absolute -bottom-6 text-[rgba(255,255,255,0.75)]">
						{{ $t('selected_days') }} {{ days }}
					</div>
				</div>
				<div class="grow relative">
					<InsuranceCalendar
						v-model="$v.arrivalDate.$model"
						:previous-date="departureDate"
						:placeholder="$t('insurance_end')"
						:input-class="'rounded-l-none lg:rounded-r-none'"
					/>
					<div v-if="!$v.arrivalDate.required && submitted" class="input-error-box bg-my-red rounded-t-md rounded-tl-none lg:rounded-tr-none">
						{{ $t('date_required') }}
					</div>
				</div>
			</div>
			<div class="flex-[0_0_auto] mb-11">
				<button
					type="submit"
					class="w-full py-3 sm:py-4 px-4 xl:px-8 rounded-md lg:rounded-l-none bg-gradient-to-b from-my-blue-from to-my-blue-to text-white text-base sm:text-lg font-bold cursor-pointer"
				>
					{{ $t('submit') }}
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2'
import InsuranceCalendar from '~/components/InsuranceCalendar'

export default {
	name: 'InsuranceSearchForm',
	components: {
		Multiselect,
		InsuranceCalendar,
	},
	data() {
		return {
			query: '',
			selectedCountries: [],
			departureDate: null,
			arrivalDate: null,
			submitted: false,
			isTouchDevice: !!(process.client && ('ontouchstart' in window || navigator.maxTouchPoints))
		}
	},
	validations: {
		selectedCountries: {
			required,
		},
		departureDate: {
			required,
		},
		arrivalDate: {
			required,
		},
	},
	async mounted() {
		if (!this.$store.getters['insurance/countries']) {
			await this.fetchCountries()
		}
		this.init()
	},
	methods: {
		init() {
			if (this.$route.name === 'insurance') {
				this.selectedCountries = typeof this.$route.query.countries === 'string' ? [this.$route.query.countries] : [...this.$route.query.countries || []]
				this.departureDate = this.formatDate(this.$route.query.begin)
				this.arrivalDate = this.formatDate(this.$route.query.end)
			}
		},
		async fetchCountries() {
			try {
				await this.$store.dispatch('insurance/getCountries')
			} catch (e) {
				if (!this.$axios.isAxiosError(e)) {
					this.$sentry?.captureException(e)
				}
			}
		},
		async filterCountries(query) {
			this.query = query
			return this.countries
		},
		async onSubmit() {
			const activeElement = document.activeElement

			if (activeElement && activeElement.tagName?.toLowerCase() === 'input' && activeElement.name === 'date') {
				return
			}

			this.submitted = true
			this.$v.$touch()

			if (!this.$v.$invalid) {
				const query = {
					type: 'avia',
					countries: this.selectedCountries,
					begin: this.departureDate.toLocaleString('ru', { year: 'numeric', month: '2-digit', day: '2-digit' }),
					end: this.arrivalDate.toLocaleString('ru', { year: 'numeric', month: '2-digit', day: '2-digit' }),
				}
				try {
					await this.$router.push({name: 'insurance', query: query})
				} catch (e) {
					if (!this.$axios.isAxiosError(e)) {
						this.$sentry?.captureException(e)
					}
				}
			}
		},
		toggleCountry(country) {
			if (this.selectedCountries.includes(country)) {
				this.selectedCountries = this.selectedCountries.filter(code => country !== code)
			} else if (this.selectedCountries.length < 5) {
				this.selectedCountries.push(country)
			}
		},
		formatDate(date) {
			return new Date(date.slice(6, 10), Number(date.slice(3, 5)) - 1, Number(date.slice(0, 2)))
		},
	},
	computed: {
		countries() {
			const countries = this.$store.getters['insurance/countries']
				? this.$store.getters['insurance/countries'].filter(country => country.code !== 'UZ')
				: []
			let first = [
				{ code: 'RU', name: this.$t('RU') },
				{ code: 'KZ', name: this.$t('KZ') },
				{ code: 'KG', name: this.$t('KG') },
				{ code: 'TR', name: this.$t('TR') },
				{ code: 'AE', name: this.$t('AE') },
				{ code: 'KR', name: this.$t('KR') },
				{ code: 'MY', name: this.$t('MY') },
				{ code: null, name: null, disabled: true },
			]

			if (countries.length !== 0) {
				countries.sort((a, b) => {
					if (a.name === b.name) {
						return 0
					}
					return a.name < b.name ? -1 : 1
				})
			}

			return [
				...first,
				...countries.filter(country => {
					return !['RU', 'KZ', 'RU', 'KG', 'TR', 'AE', 'KR', 'MY'].includes(country.code)
				})
			]
		},
		days() {
			if (this.departureDate && this.arrivalDate) {
				return Math.ceil((this.arrivalDate - this.departureDate) / (1000 * 60 * 60 * 24)) + 1
			}
			return 0
		},
	},
	watch: {
		$route() {
			this.init()
		},
	},
}
</script>
