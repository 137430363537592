<i18n lang="json5">{
	ru: {
		loading: 'Загрузка данных...',
	},
	uz: {
		loading: 'Maʼlumotlar yuklanmoqda...',
	}
}</i18n>

<template>
	<form class="relative sm:min-h-[27rem] lg:min-h-[14rem]" @submit.prevent="onSubmit">
		<div class="xl:flex">
			<div class="lg:flex xl:flex-grow">
				<div class="lg:border-my-gray-400 lg:flex-grow lg:border-r">
					<img
						src="/design/placeholder.svg"
						class="absolute left-4 sm:left-5 lg:left-6 top-3 sm:top-5 z-10 w-input-ico pointer-events-none"
						alt
					/>
					<input
						type="text"
						class="w-full py-3 sm:py-4 pl-12 sm:pl-14 pr-14 sm:pr-16 bg-white text-black text-base sm:text-lg font-bold rounded-md border-0 focus:ring-0 placeholder:text-gray-400 touch-none lg:rounded-r-none"
						placeholder="Откуда"
					>
				</div>

				<div class="relative lg:flex-grow input-fields-distance lg:mt-0 xl:border-my-gray-400 xl:border-r">
					<img
						src="/design/placeholder.svg"
						class="absolute left-4 sm:left-5 lg:left-6 top-3 sm:top-5 z-10 w-input-ico pointer-events-none"
						alt
					/>
					<input
						type="text"
						class="w-full py-3 sm:py-4 pl-12 sm:pl-14 pr-14 sm:pr-16 bg-white text-black text-base sm:text-lg font-bold rounded-md border-0 focus:ring-0 placeholder:text-gray-400 touch-none lg:rounded-l-none xl:rounded-r-none"
						placeholder="Куда"
					>
				</div>
			</div>

			<div class="lg:flex xl:flex-grow">
				<div class="input-fields-distance xl:mt-0 flex lg:flex-grow lg:w-1/2">
					<div class="flex-grow border-my-gray-400 border-r">
						<TransferCalendar
							v-model="form.departureDate[0]"
							:input-class="'rounded-r-none xl:rounded-l-none'"
							:first-of-two="true"
							:placeholder="'Туда'"
							:error="departureDateError"
							:error-text="'Укажите дату'"
							:input-class-error="'rounded-t-md rounded-tr-none xl:rounded-tl-none'"
							@update:error="departureDateError = $event"
						/>
					</div>

					<div class="flex-grow lg:border-my-gray-400 lg:border-r input-fields-distance mt-0">
						<TransferCalendar
							v-model="form.dateBack"
							:input-class="'rounded-l-none lg:rounded-r-none'"
							:previous-date="form.departureDate[0]"
							:placeholder="'Обратно'"
							:addCancel="!!form.dateBack"
						/>
					</div>
				</div>

				<div class="input-fields-distance xl:mt-0 sm:flex lg:flex-grow lg:w-1/2">
					<div class="sm:flex-grow sm:w-1/2 lg:w-1/4">
						<Passengers
							@update:adults="form.adults = $event"
							@update:children="form.children = $event"
							@update:infants="form.infants = $event"
							@update:service_class="form.class = $event"
							:adults="form.adults"
							:children="form.children"
							:infants="form.infants"
							:service_class="form.class"
							:input-class="'sm:rounded-r-none lg:rounded-l-none'"
							:simple="true"
						/>
					</div>



					<div class="sm:mt-0 sm:flex-grow text-center sm:w-1/2 lg:w-1/4 mt-4">
						<button class="w-full py-3 sm:py-4 rounded-md sm:rounded-none sm:rounded-r-md bg-gradient-to-b from-my-blue-from to-my-blue-to text-white text-base sm:text-lg font-bold cursor-pointer">
							Найти авто
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import TransferCalendar from './TransferCalendar'
import Passengers from './Passengers'
import AirportHints from './AirportHints'

export default {
	name: 'TransferSearchForm',
	components: {
		TransferCalendar,
		AirportHints,
		Passengers,
	},
	props: {
		requestData: Object,
	},
	data() {
		return {
			form: {
				departure: [],
				departureTitle: [],
				arrival: [],
				arrivalTitle: [],
				departureDate: [],
				dateBack: null,
				adults: 1,
				children: 0,
				infants: 0,
				class: 'E'
			},

			departureError: false,
			arrivalError: false,
			identicalCities: [],
			departureDateError: false,

			hintBox: false,
			airports: [],
			moses: false,
			mosesBoxes: 2,
		}
	},
	methods: {
		onSubmit() {

		}
	}
}
</script>

<style scoped>

</style>
